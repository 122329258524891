import React from "react"
import "./index.css"
import App from "./App"
import { Helmet } from "react-helmet"
// import * as serviceWorker from "./serviceWorker"
import { graphql } from "gatsby"

export default ({ data }) => {
  const { edges } = data.allFile
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <script src="https://js.pay.jp/v2/pay.js"></script>
        <title>ジョイナビ - 予約フォーム</title>
      </Helmet>
      <App tours={edges} />
    </div>
  )
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: { sourceInstanceName: { eq: "tour" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              name
              description
              thumbnail
              price
            }
          }
        }
      }
    }
  }
`
